import { grey } from '@mui/material/colors';

export default {
  MuiUseMediaQuery: {
    defaultProps: {
      noSsr: true,
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: grey[50],
      },
    },
  },
  /*   MuiButton: {
    styleOverrides: {
      sizeMedium: {
        height: '40px',
      },
    },
  }, */
  MuiButton: {
    styleOverrides: {
      sizeMedium: {
        height: '40px',
      },
      root: {
        color: '#f0f0f0',
        'border-top-color': '#7eb816',
        'border-top-style': 'solid',
        'border-top-width': '1px',
        'border-right-color': '#7eb816',
        'border-right-style': 'solid',
        'border-right-width': '1px',
        'border-bottom-color': '#7eb816',
        'border-bottom-style': 'solid',
        'border-bottom-width': '1px',
        'border-left-color': '#7eb816',
        'border-left-style': 'solid',
        'border-left-width': '1px',
        'border-image-outset': 0,
        'border-image-repeat': 'stretch',
        'border-image-slice': '100%',
        'border-image-source': 'none',
        'border-image-width': '1',
        'background-color': '#03536c',
        '&:hover': {
          backgroundColor: '#7eb816',
          color: '#03536c',
        },
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: 'small',
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    },
  },

  // eslint-disable-next-line spaced-comment
  /////////////////////////////////////
  MuiTableCell: {
    styleOverrides: {
      root: {
        backgroundColor: '#03536c',
        color: '#c2c7d0 !important',
        '&, & .MuiLink-root': {
          color: '#c2c7d0',
        },
      /*         '&, & .MuiTableCell-sizeSmall': {
          backgroundColor: '#c2c7d0',
        },
        '&, & .MuiTableCell-sizeMedium': {
          backgroundColor: '#03536c',
        }, */
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        backgroundColor: '#dae1e7',
      },
      root: {
        backgroundColor: '#03536c',
        color: '#c2c7d0 !important',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      paper: {
        backgroundColor: '#dae1e7',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        backgroundColor: '#015876',
        color: '#c2c7d0',
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        '&, & .MuiSvgIcon-root': {
          color: '#7eb816',
        },
        '&, & .MuiBottomNavigationAction-label': {
          color: '#7eb816',
        },
        '&& .Mui-selected, && .Mui-selected:hover': {
          color: '#fff',
        },
        backgroundColor: '#03536c',
        color: '#7eb816',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: '#03536c',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
      // selected and (selected + hover) states
        '&& .Mui-selected': {
          color: '#fff!important',
        },
        '&& .Mui-selected, && .Mui-selected:hover': {
          backgroundColor: '#7eb816',
          color: '#fff',
          '&, & .MuiListItemIcon-root': {
            color: '#03536c',
          },
        },
        // hover states
        '& .MuiListItemButton-root:hover': {
          backgroundColor: '#7eb816',
          color: '#fff',
          '&, & .MuiListItemIcon-root': {
            color: '#03536c',
          },
          '&, & .MuiListItemText-root:hover,': {
            color: '#fff',
          },
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
      //        '&, & .MuiBreadcrumbs-root': {
      //          color: '#fff',
      //        },
        //        color: '#c2c7d0',
      /*         '&, & .MuiTypography-body2': {
          fontStyle: 'italic',
        }, */
      },
      body1: {
        '&:hover': {
          backgroundColor: '#7eb816',
          color: '#fff',
        },
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        backgroundColor: '#dae1e7',
        // hover states
        '&:hover': {
          backgroundColor: '#7eb816',
          color: '#fff',
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        '&, & .MuiSvgIcon-root': {
          color: '#03536c',
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        backgroundColor: '#dae1e7',
        '&:hover': {
          backgroundColor: '#7eb816',
          color: '#fff',
        },
      },
    },
  },
  MuiBadge: {
    styleOverrides: {
      root: {
        /* backgroundColor: '#015876', */
        color: '#c2c7d0',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        backgroundColor: '#015876',
        color: '#c2c7d0',
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        color: '#c2c7d0',
        '&, & .MuiTypography-root': {
          color: '#c2c7d0',
        },
      },
    },
  },
  '.mapboxgl-ctrl-group:not(:empty), .maplibregl-ctrl-group:not(:empty)': {
    background: 'greenyellow',
  },

  MuiCheckbox: {
    styleOverrides: {
      root: {
        backgroundColor: '#ebf5d4',
        '&:hover': {
          backgroundColor: '#7eb816',
          color: '#fff',
        },
      },
    },
  },

};
